.falling-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;
  z-index: 9999; 
}

.falling-image {
  position: absolute;
  top: -100px; 
  width: 50px;  
  height: 50px;
  animation: fall 30s linear infinite;
}

@keyframes fall {
  0% {
    transform: translateY(-100px);
    opacity: 1;
  }
  100% {
    transform: translateY(100vh); 
    opacity: 0;
  }
}
